.banner {
    position: -webkit-sticky;
    /* position: sticky; */
    /* top: 0px; */
    height: 70px;
    font-weight: 700;
    display: flex;
    /* background-color: #3251db; */
    color: #5f5f5f;
    font-size: 20px;
    width: 100%;

    align-items: end;
    // justify-content: center;
}

.dashboard-card {
    background: #c5e7f3;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em,
        rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
    border-radius: 22.3744px;
    // padding: 50px 70px;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: bold;
    height: 150px;
    text-align: center;
    min-width: 250px;
    color: #2a75bb;
    cursor: pointer;
}

.beneficiary-question-section {
    background: #c5e7f3;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.bg-common {
    background: #c5e7f3;
}

.repeat-box {
    padding: 10px;
    background: #c5e7f3;
    margin-bottom: 10px;
    margin-top: 10px;
}

.options-main-container {
    margin-top: 10px;
    padding: 15px 10px;
    background-color: #c5e7f3;
}

.successCheck {
    width: 100px;
    height: 100px;
    color: green;
}

.all-to-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #2a75bb;
}

.mandatory {
    sup {
        color: red;
        font-size: 14px;
    }
}

.info {
    padding-top: 10px;
    padding-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .nb {
        padding-top: 5px;
        padding-bottom: 5px;
        font-weight: 700;
    }

    .mandatory {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.label {
    font-weight: 700;
}

.form-check-label {
    padding-right: 10px;
    padding-bottom: 10px;
}

.generalinfo {
    .manage {
        .form-section {
            .form-container {
                .form-style {
                    .form-group {
                        label {
                            font-size: 12px;
                            font-weight: 600;
                            // text-transform: capitalize;
                            font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
                        }

                        input.form-control {
                        }
                    }
                }
            }
        }

        .form-group {
        }
    }

    .view {
        position: relative;

        .edit {
            display: flex;
            width: 100%;
            justify-content: flex-end;
            margin-bottom: 30px;
        }

        .table-section {
            table {
                tr:nth-child(odd) {
                    td {
                        background: #f6f6f7 !important;
                        color: #5f5f5f;
                        border-top: 0px;
                    }
                }

                tr:nth-child(even) {
                    td {
                        background: #f1efdc !important;
                    }
                }

                td {
                    font-weight: 700;
                }
            }
        }
    }
}
