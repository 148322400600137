.generalinfo .view .table-section table tr:nth-child(odd) td {
    /* background-color: #0e71c7 !important; */
    color: #fff;
    border-radius: 2px;
    font-size: 13px !important;
    background-color: #215c94;
}

.table-section table thead th {
    border: solid 2px #508fd3 !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f6f6f7;
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: #f1eedc;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f6f6f7;
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: #f1eedc;
}

.table-head {
    background-color: #508fd3;
    color: #fff;
}

tbody {
    font-size: 13px;
}

.preview-page {
    padding: 30px 25px;
    margin-top: 30px;
    font-size: 16px !important;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(50, 50, 93, 0.25) 0px 2px 5px -1px;
    color: #f3f8fb;
    background-color: #c5e7f3;
}

.permanent-area {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    background-color: #c5e7f3;
    padding: 10px;
}

.container-width {
    padding: 0px !important;
}

.table-bg {
    background-color: #c5e7f3 !important;
}

.table-width {
    width: 100px;
}

.plus-icon {
    cursor: pointer;
}

.user-profile {
    display: flex !important;
    justify-content: center;
    align-items: center !important;
}

.handleDataEdit {
    font-size: 25px;
    color: #508fd3;
}

.buttonData {
    background-color: #508fd3;
    color: white;
    border-color: #508fd3;
}

.submitData {
    background-color: #c5e7f3;
    color: #508fd3;
}

.editicon {
    font-size: 25px;
}

.table {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

tr:nth-child(odd) td {
    /* border-top: 1px solid #f6f6f7; */
}

tr:nth-child(even) td {
    border: none;
}

.tab-dropdown {
    font-weight: bold !important;
    width: 100% !important;
    background-color: #efefef !important;
    border: #efefef !important;
    color: #6c757d !important;
}

.table-icon {
    font-size: 15px;
    margin: 4px;
    font-weight: 400;
}

.excel-icon {
    font-size: 25px;
    display: flex;
    float: right;
}

.infrastructuregrantarea {
    font-size: 14px;
    letter-spacing: 1px;
}

.edit-button {
    float: right;
}

.create-button {
    float: right;
}

.hyper_link:hover {
    color: blue;
    font-weight: 900;
}

.main-heading {
    font-size: 18px;
    font-size: 600;
}

.new-table tbody tr:nth-child(even) {
    background-color: #f6f6f7;
}

.new-table tbody tr:nth-child(odd) {
    background-color: #f1eedc;
}

.width-controller {
    width: 35% !important;
}

.check-box-area {
    font-size: 14px;
}

.flag-status {
    font-weight: 600;
}

.label {
    font-size: 13px;
}

.bredcrumb_main:hover {
    color: blue;
    font-weight: 700;
}

.login-bg {
    background-image: url('../images/login-bg.jpg');
    background-size: contain;
}
.capitalize::first-letter {
    text-transform: uppercase;
  }

  .delete {
    font-size: 28px;
  }


  #fileInputOrders::file-selector-button {
    background-color: #2a75bb; /* Your desired background color */
    padding: 10px;
    border: none;
    color: #fff;
    border-radius: 5px;
    cursor: pointer;
  }

 /* Add this CSS to your main CSS file */
@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  .blink {
    animation: blink 1s infinite;
    color: #40A578;
  }
  
